<template>
<div class="carousel-item">
    <!-- vuejs-accessibility/no-static-element-interactions -->
    <div
        class="survey-feature"
        role="button" tabindex="0"
        v-on:click.self="navigateToProductPage"
        v-on:keydown.enter="navigateToProductPage"
    >
        <div />
        <div class="overlay">
            <NuxtLink :to="localeRoute({name: 'games-gameslug', params: { gameslug: 'the-jackbox-survey-scramble' }})">
                <img
                    tabindex="0"
                    :src="`/images/homepage/features/survey-scramble/survey-wordmark.png`" alt="The Jackbox Survey Scramble"
                    class="wordmark" role="button"
                >
            </NuxtLink>
            <div class="notes">
                <div class="steam-users">
                    <img class="logo" src="/images/icons/steam-icon.svg" alt="Steam">
                    <p>For Steam</p>
                </div>
                <p v-if="!game?.displayWishlistUI" class="release-date"><i>{{ $t('PACK_DETAIL.AVAILABLE_NOW') }}!</i></p>
                <p v-else class="release-date"><i>{{ getReleaseDate() }}</i></p>
            </div>
            <AddToCartButton v-if="!game?.displayWishlistUI" :product="product" :pack="game" />
            <a v-else href="https://store.steampowered.com/app/2948640/The_Jackbox_Survey_Scramble/" target="_blank">
                <button class="yellow wide icon-button">
                    <p>{{ $t('HOME.WISHLIST') }}</p>
                    <div class="icons">
                        <img src="/images/icons/windows.svg" alt="Windows" class="windows">
                        <img src="/images/icons/apple.svg" alt="Mac" class="apple">
                        <img src="/images/icons/linux.svg" alt="Linux" class="linux">
                    </div>
                </button>
            </a>
            <NuxtLink :to="localeRoute({name: 'games-gameslug', params: {gameslug: 'the-jackbox-survey-scramble'}})">
                <button class="outline wide icon-button">
                    <p>{{ $t('GAME_DETAIL.LEARN_MORE') }}</p>
                    <div class="icons">
                        <img src="/images/icons/arrow-right.svg" alt="->" class="arrow-right">
                    </div>
                </button>
            </NuxtLink>
            <StorefrontIcons
                v-if="!game?.displayWishlistUI"
                :pack="game" :platform-options="platformOptions" :fell-back-to-en="fellBackToEn"
                :is-localized="false"
            />
        </div>
    </div>
</div>
</template>

<script lang="ts">
import { Shopify } from '@jackboxgames/shopify'
import { getStorefrontsFromProductData } from '$services/storefronts/storefronts'
import AddToCartButton from '$components/shopping/AddToCartButton.vue'
import StorefrontIcons from '$components/shopping/StorefrontIcons.vue'
import type { JBGWeb } from '$types/JBGWeb'

export default defineNuxtComponent({
    components: {
        AddToCartButton,
        StorefrontIcons
    },
    data() {
        return {
            game: <JBGWeb.GameDetail | null>null,
            product: <Shopify.Product | null>null,
            fellBackToEn: false
        }
    },
    async asyncData({ $cms, $i18n }) {
        const data = {
            game: <JBGWeb.GameDetail | null>null,
            product: <Shopify.Product | null>null,
            fellBackToEn: false
        }

        try {
            const game: JBGWeb.GameDetail = await $cms.fetchOne('game-details', 'the-jackbox-survey-scramble', {
                populate: ['shopifyProduct'],
                locale: $i18n.locale.value
            })

            data.game = game
        } catch (error) {
            if ((error as Error).message === 'No data') {
                try {
                    // force a fetch in en
                    data.fellBackToEn = true
                    const game: JBGWeb.GameDetail = await $cms.fetchOne('game-details', 'the-jackbox-survey-scramble', {
                        populate: ['shopifyProduct'],
                        locale: 'en'
                    })

                    data.game = game
                } catch (error) {
                    console.error(error)
                }
            } else {
                console.error(error)
            }
        }
        data.product = data.game.shopifyProduct
        return data
    },
    computed: {
        platformOptions() {
            if (!this.product) return
            return getStorefrontsFromProductData(this.product)
        }
    },
    methods: {
        getReleaseDate() {
            // funky UTC stuff to get the right date and also give it a localized format
            const date = new Date('2024-10-24')
            const timeDiff = date.getTimezoneOffset() * 60000
            const adjustedDate = new Date(date.valueOf() + timeDiff)
            return adjustedDate.toLocaleDateString(this.$i18n.locale, { month: 'long', day: 'numeric', year: 'numeric' })
        },
        navigateToProductPage() {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            void this.$router.push(this.localeRoute({ name: 'games-gameslug', params: { gameslug: 'the-jackbox-survey-scramble' } }))
        }
    }
})
</script>

<style lang="scss" scoped>
@import "../../../styles/kit.scss";

.survey-feature {
    display: flex;
    width: 100%;
    height: 100%;
    background-image: url('/images/homepage/features/survey-scramble/carousel-placeholder-wide.jpg');
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.24) 26.97%, rgba(0, 0, 0, 0.07) 42.52%, rgba(0, 0, 0, 0.00) 100%),
        image-set(url(/images/homepage/features/survey-scramble/carousel-placeholder-wide.jpg));
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    flex-direction: row-reverse;
    justify-content: space-between;
    position: relative;

    @include mq-xsmall {
        background-image: url('/images/homepage/features/survey-scramble/carousel-placeholder-mobile.jpg');
        background:
            linear-gradient(180deg, rgba(0, 0, 0, 0.00)48.54%, rgba(0, 0, 0, 0.5) 60.18%),
            image-set(url(/images/homepage/features/survey-scramble/carousel-placeholder-mobile.jpg)),;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: bottom center;
        flex-direction: column;
        padding-bottom: 16px;
    }

    .overlay {
        margin: auto 80px;
        max-width: 320px;
        display: flex;
        flex-direction: column;

        @include mq-medium {
            max-width: 280px;
        }

        @include mq-small {
            margin: auto 40px;
            max-width: 320px;
            min-width: 220px;
            width: 35vw;
        }

        @include mq-xsmall {
            margin-bottom: 20px;
            padding: 0 16px;
            flex-direction: column;
            width: 100%;
            align-self: center;
        }

        .notes {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 12px;

            .steam-users {
                display: flex;
                flex-direction: row;
                gap: 6px;

                p{
                margin: 0;
                }
            }

            .release-date {
                display: flex;
                align-items: center;
                margin: 0;
                font-style: italic;
                font-weight: 600;
                font-size: 12px;
                line-height: 12px;
                text-transform: uppercase;
                text-align: right;
            }
        }

        button {
            height: 48px;
            font-size: 16px;
            line-height: 16px;
            margin: 0;
            text-align: left;

            &.yellow {
                background: linear-gradient(90deg, #FAD000 0%, #FFF478 100%);
                border: none;
                font-weight: 800;
                text-transform: uppercase;
                margin: 8px 0px;

                &:hover {
                    background: linear-gradient(90deg, #f8e272 0%, #f5efae 100%);
                    box-shadow: 0px 0px 27.901px 0px #FFD816, 0px 0px 1.329px 0px #FFD816, 0px 0px 0.664px 0px #FFD816;
                }
            }
            &.outline {
                &:hover {
                    border: 2px solid #FFE461;
                    color: #FFE461;
                    .arrow-right {
                        filter: sepia(95%) saturate(1011%) hue-rotate(352deg) brightness(100%) contrast(80%);
                    }
                }
            }

            @include mq-small-and-less {
                font-size: 14px;
                line-height: 14px;
                width: 280px
            }
        }

        .icon-button {
            p {
                margin: 0;
            }

            display: flex;
            align-items: center;
            justify-content: space-between;

            .icons {
                display: flex;
                gap: 8px;
                align-items: center;

                img {
                    &.arrow-right {
                        height: 18px;
                        width: 15.429px;
                    }

                    &.windows {
                        height: 16px;
                        width: 17px;
                    }

                    &.apple {
                        height: 16px;
                        width: 14px;
                    }

                    &.linux {
                        height: 18px;
                        width: 15px;
                    }

                    @include mq-small-and-less {
                        &.arrow-right {
                            height: 16px;
                            width: 13.715px;
                        }
                    }
                }
            }
        }

        .wordmark {
            width: 100%;
            cursor: pointer;
            &:hover {
                transform: scale(1.10)
            }
        }
    }
}
</style>
