<template>
<slot v-if="isControl" name="control" />
<slot v-if="!isControl" name="alt" />
</template>

<script lang="ts">
import { defineComponent } from 'vue'


export default defineComponent({
    props: {
        test: String,
        pct: {
            type: Number,
            default: 0.1
        }
    },
    data() {
        return {
            abId: <number>0
        }
    },
    computed: {
        isControl() {
            if (process.server) return true
            return this.dumbRNG(this.test || '') >= this.pct
        }
    },
    mounted() {
        if (this.$gtm) {
            this.$gtm.trackEvent({
                event: 'Mixpanel-Experiment-Started',
                experiment: {
                    name: this.test,
                    variant: this.isControl ? 'control' : 'alternate'
                }
            })
        }
    },
    methods: {
        getId() {
            // Check if the A/B Test cookie is set.
            const abIdString = localStorage.getItem('ab-test')

            if (!abIdString || abIdString === '0') {
                this.abId = Math.random()
                localStorage.setItem('ab-test', this.abId.toString())
            } else {
                this.abId = Number(abIdString)
            }
        },
        dumbRNG(str: string): number {
            if (!this.abId) {
                this.getId()
            }
            let n = 0
            for (let i = 0; i < str.length; i++) {
                n = str.charCodeAt(i) * (i * 13)
            }
            const m = 2 ** 35 - 31
            const a = 185852
            const s = (this.abId * n * Number.MAX_SAFE_INTEGER) % m
            const r = ((s * a) % m) / m
            return r
        }
    }
})
</script>
